<template>
  <div>
    <PageHeader main-header="nav-bar.menu" />
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <BaseBlockButton
          color="amber lighten-3"
          label="dish.add-dish"
          iconName="$addDish"
          @action="openNewDish"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <BaseBlockButton
          color="purple lighten-3"
          label="dish.menu-settings"
          iconName="$adjust"
          @action="openMenuSettings"
        />
      </v-col>
    </v-row>
    <DishList edit />
    <DishDetails
      v-if="dishDialog"
      :dialog.sync="dishDialog"
      :dish="selectedDish"
      :update="update"
    />
    <MenuSettings v-if="menuSettingsDialog" :dialog.sync="menuSettingsDialog" />
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader.vue'
import DishList from '@/components/dishes/DishList'
import { mapState } from 'vuex'

const DishDetails = () => import('@/components/dishes/DishDetails')
const MenuSettings = () => import('@/components/common/MenuSettings')

export default {
  name: 'DishManagement',

  components: {
    PageHeader,
    DishList,
    DishDetails,
    MenuSettings
  },

  data: () => ({
    baseDish: {
      viName: null,
      enName: '',
      category: null,
      priceTBD: false,
      fromKitchen: true,
      price: null,
      imgUrl: '',
      description: '',
      serving: true
    },
    selectedDish: {},
    dishDialog: false,
    update: false,
    menuSettingsDialog: false
  }),

  computed: {
    ...mapState('dish', ['dialogStatus'])
  },

  mounted() {
    this.$mitt.on('openEditDish', this.openEditDish)
  },
  beforeDestroy() {
    this.$mitt.off('openEditDish', this.openEditDish)
  },

  methods: {
    openNewDish() {
      this.update = false
      this.selectedDish = this.baseDish
      this.dishDialog = true
    },

    openEditDish(dish) {
      this.update = true
      this.selectedDish = dish
      this.dishDialog = true
    },

    openMenuSettings() {
      this.menuSettingsDialog = true
    }
  }
}
</script>

<style></style>
